.clients {
    background-color: var(--color-bg-dark);
    padding: 7rem 20rem 0 20rem;
}

.clients_item {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    gap: 5rem;
}

.clients p {
    color: white;
}

.clients img {
    height: 3rem;
}

.clients img:hover {
    filter: brightness(0) invert(1);
}

@media screen and (max-width: 1200px) {
    .clients img {
        height: 2.8rem;
    }
}

@media screen and (max-width: 1024px) {
    .clients img {
        height: 2.5rem;
    }

    .clients_item {
        gap: 4rem;
    }
}

@media screen and (max-width: 768px) {
    .clients {
        padding: 5rem 10rem 0 10rem;
    }

    .clients img {
        height: 2rem;
    }

    .clients_item {
        gap: 3rem;
    }
}

@media screen and (max-width: 480px) {
    .clients {
        padding: 5rem 2rem 0 2rem;
    }

    .clients img {
        height: 1rem;
    }

    .clients_item {
        gap: 0.9rem;
    }
}

@media screen and (max-width: 320px) {
    .clients {
        padding: 3rem 1rem 0 1rem;
    }

    .clients img {
        height: 0.7rem;
    }

    .clients_item {
        gap: 0.45rem;
    }
}