@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');

:root {
    --font-heading: 'Space Mono', monospace;
    --font-family: 'Roboto', sans-serif;

    --color-primary: #64F4AC;
    --color-bg-lighter: #333438;
    --color-bg-light: #2D2E31;
    --color-bg-dark: #25262A;
    --color-text-grey: #8C8D90;
    --color-text-white: #FFFFFF;
}

body {
    background-color: var(--color-bg-dark);
}