.navbar {
    background-color: var(--color-bg-light);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 6rem;
    z-index: 100;
    left: 0;
    right: 0;
    position: fixed;
}

.navbar-shadow {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.active-tab {
    color: var(--color-primary) !important;
}

.navbar-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar-links_logo {
    display: flex;
    color: var(--color-text-white);
    font-family: var(--font-heading);
    font-size: 1.7rem;
    cursor: pointer;
}

.navbar-links_logo_first {
    font-weight: bold;
    margin-right: 0.5rem;
}

.navbar-links_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    margin-left: 5rem;
}

.navbar-links_container a {
    margin-left: 3.5rem;
    text-decoration: none;
    font-family: var(--font-heading);
    color: var(--color-text-white);
}

.navbar-links_container a:hover {
    color: var(--color-primary);
}

.navbar-social {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar-links_social-item {
    display: flex;
    align-items: center;
    text-decoration: none;
    margin-left: 3rem;
}

.navbar-links_container_social-icon {
    font-size: 17px;
    align-items: center;
    color: var(--color-text-white);
}

.navbar-links_container_social-text {
    margin-left: 0.5rem;
    font-size: 13px;
    font-weight: 400;
    font-family: var(--font-family);
    color: var(--color-text-grey);
}

.navbar-links_social-item:hover .navbar-links_container_social-icon,
.navbar-links_social-item:hover .navbar-links_container_social-text {
    color: var(--color-primary);
}

.navbar-links_social_email {
    margin-left: 4rem;
    background-color: var(--color-bg-lighter);
    font-size: 3rem;
    padding: 0.8rem;
    border-radius: 2rem;
    color: var(--color-primary);
    cursor: pointer;
}

.navbar-links_social_email:hover {
    box-shadow: 7px 7px 15px rgba(0, 0, 0, 0.2)
}

.navbar-menu {
    display: none;
    position: relative;
    cursor: pointer;
}

.navbar-menu_container {
    text-align: end;
    background: var(--color-bg-dark);
    padding: 2rem;
    position: absolute;
    top: 50px;
    right: 0;
    margin-top: 3rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.2);
    overflow-y: auto;
    max-height: 80vh;
}

.navbar-menu_container-links {
    font-family: var(--font-heading);
    display: flex;
    flex-direction: column;
}

.navbar-menu_container-links a {
    text-decoration: none;
    color: var(--color-text-white);
    margin-top: 1rem;
}

.navbar-menu_container-links-social {
    margin-top: 3rem;
}

.navbar-menu_container-links-social .navbar-links_social-item {
    display: flex;
    justify-content: end;
    margin-top: 1rem;
}

.navbar-menu_container-links-social .navbar-links_social_email {
    margin-top: 1rem;
}

.navbar-menu_container-links-social {
    display: none;
}

@media screen and (max-width: 1200px) {
    .navbar-links_container {
        display: none;
    }

    .navbar-menu {
        display: block;
    }
}

@media screen and (max-width: 768px) {
    .navbar {
        padding: 1rem 3rem;
    }
    .navbar-social {
        display: none;
    }

    .navbar-menu_container {
        top: 20px;
    }

    .navbar-menu_container-links-social {
        display: block;
    }
}

@media screen and (max-width: 480px) {
    .navbar {
        padding: 0.5rem 1.5rem;
    }

    .navbar-social {
        display: none;
    }

    .navbar-menu_container {
        top: 20px;
    }

    .navbar-menu_container-links-social {
        display: block;
    }

    .navbar-links_logo {
        font-size: 1.2rem;
    }

    .navbar-menu .navbar-links_social_email {
        font-size: 2rem;
        padding: 0.4rem;
        margin-left: 0;
    }
}