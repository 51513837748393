.contact {
    background-color: var(--color-bg-light);
    padding: 10rem 10rem 6rem 10rem;
    font-family: var(--font-heading);
}

.contact_container {
    display: flex;
    justify-content: space-between;
}

.contact_left-container {
    width: 40%;
}

.contact_left-container_heading {
    font-size: 2rem;
    color: var(--color-text-white);
}

.contact_left-container_heading-saying {
    display: flex;
    gap: 1.3rem;
    margin-top: 3rem;
}

.contact_left-container_saying {
    font-size: 2rem;
    color: var(--color-primary);
    text-decoration: underline;
    cursor: pointer;
}

/*---CONTACT-FORM---*/

/*.contact__title{*/
/*    font-size: 2rem;*/
/*    margin-top: 5rem;*/
/*    margin-bottom: 2rem;*/
/*    font-weight: 500;*/
/*    color: var(--color-primary);*/
/*}*/

/*.contact__form-div{*/
/*    position: relative;*/
/*    margin-bottom: 2rem;*/
/*    height: 4rem;*/
/*}*/

/*.contact__form-input{*/
/*    position: absolute;*/
/*    top: 0;*/
/*    left: 0;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    border: 1px solid var(--color-text-grey);*/
/*    background: none;*/
/*    color: var(--color-text-white);*/
/*    font-family: var(--font-heading);*/
/*    outline: none;*/
/*    padding: 1.5rem;*/
/*    border-radius: 0.3rem;*/
/*}*/

/*.contact__form-tag{*/
/*    position: absolute;*/
/*    top: -1rem;*/
/*    left: 1.25rem;*/
/*    font-size: 1rem;*/
/*    padding: .25rem;*/
/*    color: var(--color-text-grey);*/
/*    background-color: var(--color-bg-light);*/
/*    z-index: 10;*/
/*}*/

/*.contact__form-area{*/
/*    height: 11rem;*/
/*}*/

/*.contact__form-area textarea{*/
/*    resize: none;*/
/*}*/

/*.contact__form-div-button {*/
/*    color: var(--color-bg-dark);*/
/*    background-color: var(--color-primary);*/
/*    font-family: var(--font-heading);*/
/*    padding: 1rem 2rem;*/
/*    border-radius: 0.2rem;*/
/*    font-weight: bold;*/
/*    font-size: 1rem;*/
/*    border: none;*/
/*    outline: none;*/
/*    cursor: pointer;*/
/*}*/

/*---RIGHT-CONTAINER---*/

.contact_right-container {
    width: 35%;
}

.contact_right-container_heading {
    color: var(--color-text-grey);
    font-size: 1rem;
    font-weight: 700;
}

.contact_right-container_address {
    font-size: 1.5rem;
    color: var(--color-text-grey);
    margin-top: 2rem;
    margin-bottom: 0.7rem;
}

.contact_right-container_email {
    font-size: 1.5rem;
    color: var(--color-text-white);
}

.contact_right-container-tabs {
    tab-size: 1rem;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
}

.contact_right-container-tabs a {
    color: var(--color-text-white);
    margin-top: 1rem;
    text-decoration: none;
    width: auto;
    display: inline;
}

.contact_right-container-tabs a:hover {
    color: var(--color-primary);
}

/*---FOOTER---*/
.contact_footer-container {
    display: flex;
    align-items: center;
    margin-top: 10rem;
}

.contact_footer-container_left {
    width: 65%;
    display: flex;
    gap: 10rem;
    align-items: center;
}

.contact_footer-name {
    display: flex;
    gap: 0.6rem;
    font-size: 1.6rem;
    color: var(--color-text-white)
}

.contact_footer-copyright {
    font-size: 1rem;
    color: var(--color-text-grey);
}

.contact_footer_first-name {
    font-weight: bold;
}

.contact_footer-container_right {
    width: 35%;
    display: flex;
    gap: 1.5rem;
}

.contact_footer-container_right a {
    color: var(--color-text-white);
    font-size: 1.3rem;
}

.contact_footer-container_right a:hover {
    color: var(--color-primary);
}

@media screen and (max-width: 1200px) {

    .contact_left-container_heading {
        font-size: 1.5rem;
    }

    .contact_left-container_heading-saying {
        gap: 1rem;
    }

    .contact_left-container_saying {
        font-size: 1.5rem;
    }

    .contact_right-container_heading {
        font-size: 0.8rem;
    }

    .contact_right-container_address {
        font-size: 1.2rem;
    }

    .contact_right-container_email {
        font-size: 1.3rem;
    }

    .contact_right-container-tabs {
        margin-top: 1rem;
    }

    .contact_right-container-tabs a {
        font-size: 0.9rem;
    }

    /*---FOOTER---*/

    .contact_footer-container_left {
        gap: 3rem;
    }

    .contact_footer-name {
        font-size: 1.4rem;
    }

    .contact_footer-copyright {
        font-size: 0.8rem;
        color: var(--color-text-grey);
    }

    .contact_footer_first-name {
        font-weight: bold;
    }

    .contact_footer-container_right a {
        font-size: 1rem;
    }
}

@media screen and (max-width: 1024px) {
    .contact {
        padding: 10rem 5rem 6rem 5rem;
    }

    .contact_left-container_heading {
        font-size: 1.5rem;
    }

    .contact_left-container_saying {
        font-size: 1.5rem;
    }

    .contact_right-container_address {
        font-size: 1.3rem;
    }

    .contact_right-container_email {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 768px) {

    .contact_right-container {
        width: 50%;
    }

    .contact_left-container {
        width: 50%;
    }

    .contact_left-container_heading {
        font-size: 1.3rem;
    }

    .contact_left-container_saying {
        font-size: 1.3rem;
    }

    .contact_right-container_address {
        font-size: 1.1rem;
    }

    .contact_right-container_email {
        font-size: 1.3rem;
    }

    .contact_footer-container_left {
        gap: 2rem;
    }

    .contact_footer-name {
        font-size: 1.2rem;
    }

    .contact_footer-copyright {
        font-size: 0.6rem;
    }

    .contact_footer-container_right a {
        font-size: 0.8rem;
    }
}

@media screen and (max-width: 480px) {
    .contact {
        padding: 5rem 2rem 5rem 2rem;
    }

    .contact_container {
        flex-direction: column;
    }
    .contact_right-container {
        width: 100%;
    }

    .contact_left-container {
        width: 100%;
    }

    .contact_right-container_heading {
        margin-top: 5rem;
    }

    .contact_right-container_address {
        margin-top: 1rem;
    }

    .contact_right-container_email {
        font-size: 1.2rem;
    }

    .contact_footer-container_left {
        flex-direction: column;
    }

    .contact_footer-container {
        flex-direction: column;
    }

    .contact_footer-container_left {
        width: 100%;
    }

    .contact_footer-container_right {
        width: 100%;
        margin-top: 3rem;
        justify-content: center;
    }

    .contact_footer-name {
        font-size: 1.2rem;
    }

    .contact_footer-copyright {
        font-size: 0.6rem;
    }

    .contact_footer-container_right a {
        font-size: 0.8rem;
    }
}