.about {
    background-color: var(--color-bg-dark);
    padding: 10rem 10rem 3rem 10rem;
}

.about_content {
    display: flex;
}

.about_content_left {
    width: 100%;
    margin-right: 2rem;
}

.about_heading {
    font-family: var(--font-heading);
    color: var(--color-text-grey);
    font-size: 1rem;
    font-weight: 700;
}

.about_skills-items {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
    gap: 1rem;
}

.about-items_item {
    display: inline;
    font-family: var(--font-heading);
    font-size: 0.8rem;
    font-weight: 600;
    border-radius: 0.15rem;
    padding: 0.3rem 0.6rem;
    cursor: pointer;
}

.about-items_item:hover {
    box-shadow: 7px 7px 15px rgba(0, 0, 0, 0.2)
}

/*---Service Item Container---*/
.about_skills-service_item_container {
    width: 100%;
    margin-top: 5rem;
}

.about_service-items {
    padding: 2rem;
    background-color: var(--color-bg-light);
    border-radius: 1rem;
    margin-top: 1rem;
    font-family: var(--font-heading);
    cursor: pointer;
    margin-right: 5rem;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05)
}

.about_service-item-row {
    display: flex;
    justify-content: space-between;
}

.about_service-items_name {
    font-weight: 500;
    font-size: 2rem;
    color: var(--color-text-white);
}

.about_service-items_icon {
    width: 2rem;
    height: 2rem;
    color: var(--color-primary);
}

.about_service-items_description {
    color: var(--color-text-grey);
    font-size: 1rem;
}

.about_service-items_project_count {
    color: var(--color-text-white);
    font-size: 0.8rem;
    margin-top: 2rem;
}

.about_service-items:hover .about_service-items_name {
    color: var(--color-primary);
}

.about_service-items:hover {
    box-shadow: 5px 5px 100px rgba(0, 0, 0, 0.1);
}

/*---Content Right---*/
.about_content_right {
    width: 100%;
    margin-left: 2rem;
    font-family: var(--font-heading);
}

.about_content-introduction_heading {
    margin-top: 1rem;
    color: var(--color-text-white);
    font-size: 3rem;
}

.about_content-introduction_bio {
    margin-top: 2rem;
    color: var(--color-text-white);
    font-style: italic;
    font-size: 2rem;
}

.about_content-introduction_summery {
    margin-top: 3rem;
    color: var(--color-text-grey);
    font-size: 0.92rem;
}

/*------RESPONSIVE------*/

@media screen and (max-width: 1200px) {

    .about_heading {
        font-size: 0.8rem;
    }

    .about-items_item {
        font-size: 0.8rem;
        border-radius: 0.1rem;
        padding: 0.2rem 0.4rem;
    }

    .about_skills-service_item_container {
        margin-top: 4rem;
    }

    .about_service-items {
        padding: 1.6rem;
        border-radius: 0.8rem;
    }

    .about_service-items_name {
        font-size: 1.6rem;
    }

    .about_service-items_icon {
        width: 1.6rem;
        height: 1.6rem;
    }

    .about_service-items_description {
        font-size: 0.8rem;
    }

    .about_service-items_project_count {
        font-size: 0.6rem;
        margin-top: 1.6rem;
    }

    .about_content-introduction_heading {
        font-size: 2rem;
    }

    .about_content-introduction_bio {
        margin-top: 2rem;
        color: var(--color-text-white);
        font-style: italic;
        font-size: 1.5rem;
    }

    .about_content-introduction_summery {
        margin-top: 3rem;
        color: var(--color-text-grey);
        font-size: 0.85rem;
    }
}

@media screen and (max-width: 1024px) {

    .about-items_item {
        font-size: 0.7rem;
        border-radius: 0.1rem;
        padding: 0.2rem 0.4rem;
    }

    .about_service-items {
        padding: 1.5rem;
        border-radius: 0.75rem;
    }

    .about_service-items_name {
        font-size: 1.2rem;
    }

    .about_service-items_icon {
        width: 1rem;
        height: 1rem;
    }

    .about_service-items_description {
        font-size: 0.8rem;
        margin-top: 0.5rem;
    }

    .about_content-introduction_heading {
        font-size: 1.5rem;
    }

    .about_content-introduction_bio {
        font-size: 1.2rem;
    }

    .about_content-introduction_summery {
        font-size: 0.8rem;
    }
}

@media screen and (max-width: 768px) {
    .about {
        padding: 8rem 5rem 3rem 5rem;
    }

    .about_content {
        flex-direction: column;
    }

    .about_service-items {
        margin-right: 0;
    }

    .about_content_right {
        margin-top: 5rem;
        margin-left: 0;
    }
}

@media screen and (max-width: 480px) {
    .about {
        padding: 6rem 1.5rem 2rem 1.5rem;
    }

    .about_content {
        flex-direction: column;
    }

    .about_service-items {
        margin-right: 0;
    }

    .about_content_right {
        margin-top: 5rem;
        margin-left: 0;
    }
}

@media screen and (max-width: 320px) {
    .about {
        padding: 5rem 1rem 2rem 1rem;
    }
}