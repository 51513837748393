.portfolio {
    background-color: var(--color-bg-dark);
    font-family: var(--font-heading);
    padding: 10rem 10rem 5rem 10rem;
    display: flex;
    flex-direction: column;
}

.portfolio_content_container {
    cursor: pointer;
    margin-bottom: 5rem;
    display: flex;
    flex-direction: row;
    border-radius: 1rem;
}

.portfolio_content_container:hover {
    box-shadow: 15px 15px 30px rgba(0, 0, 0, 0.1)
}

.portfolio_heading {
    font-family: var(--font-heading);
    color: var(--color-text-grey);
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

.portfolio_content_container_image {
    width: 50%;
    height: fit-content;
    border-radius: 1rem;
}

.portfolio_content_container_details {
    margin-left: 2rem;
}

.portfolio_content_container_name {
    font-weight: 700;
    color: var(--color-text-white);
    font-size: 2rem;
}

.portfolio_content_container_codes {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 1rem;
}

.portfolio_content_container_codes p {
    color: var(--color-bg-dark);
    background-color: var(--color-primary);
    font-size: 0.8rem;
    font-weight: 600;
    border-radius: 0.15rem;
    padding: 0.3rem 0.6rem;
}

.portfolio_content_container_description {
    margin-top: 2rem;
    color: var(--color-text-grey);
}

.portfolio_all_projects {
    color: var(--color-primary);
    font-size: 2rem;
    font-weight: 700;
    text-decoration: underline;
    text-align: center;
}

@media screen and (max-width: 1200px) {

    .portfolio_content_container {
        flex-direction: column;
    }

    .portfolio_content_container_image {
        width: 100%;
    }

    .portfolio_content_container_details {
        margin-left: 0;
        margin-top: 2rem;
    }
}

@media screen and (max-width: 1024px) {

    .portfolio_content_container_name {
        font-size: 1.6rem;
    }

    .portfolio_content_container_codes p {
        font-size: 0.7rem;
    }

    .portfolio_content_container_description {
        font-size: 0.9rem;
    }

    .portfolio_all_projects {
        font-size: 1.6rem;
    }
}

@media screen and (max-width: 768px) {

    .portfolio {
        padding: 5rem 5rem 2rem 5rem;
    }

    .portfolio_content_container_name {
        font-size: 1.4rem;
    }

    .portfolio_content_container_codes p {
        font-size: 0.6rem;
    }

    .portfolio_content_container_description {
        font-size: 0.8rem;
    }

    .portfolio_all_projects {
        font-size: 1.4rem;
    }
}

@media screen and (max-width: 480px) {

    .portfolio {
        padding: 5rem 1.5rem 2rem 1.5rem;
    }

    .portfolio_content_container_details {
        margin-top: 1.2rem;
    }

    .portfolio_content_container_image {
        border-radius: 0.35rem;
    }

    .portfolio_all_projects {
        font-size: 1.3rem;
    }
}