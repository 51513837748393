.header {
    background-color: var(--color-bg-light);
    padding: 8rem 0 0 10rem;
    display: flex;
}

.header_left-content {
    margin-right: 3rem;
    width: 50%;
}

.header_left-content_middle {
    margin-left: 8rem;
}

.header_left-content_middle-designation {
    display: flex;
    gap: 2rem;
    margin-top: 5rem;
}

.header_left-content_middle-designation p {
    color: var(--color-bg-dark);
    background-color: var(--color-primary);
    font-family: var(--font-heading);
    font-size: 0.8rem;
    font-weight: 600;
    border-radius: 0.15rem;
    padding: 0.3rem 0.6rem;
    cursor: pointer;
}

.header_left-content_middle-designation p:hover {
    box-shadow: 7px 7px 15px rgba(0, 0, 0, 0.2)
}

.header_left-content_middle-heading {
    color: var(--color-text-white);
    font-family: var(--font-heading);
    font-size: 2.2rem;
    margin-top: 2.5rem;
}

.header_left-content_middle-description {
    color: var(--color-text-grey);
    font-family: var(--font-heading);
    font-size: 1rem;
    margin-top: 2.5rem;
    margin-right: 5rem;
}

.header_left-content_middle button {
    color: var(--color-bg-dark);
    background-color: var(--color-primary);
    font-family: var(--font-heading);
    text-decoration: none;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 0.25rem;
    margin-top: 4rem;
    display: inline-block;
    border: none;
    outline: none;
    cursor: pointer;
}

.header_left-content_middle button:hover {
    box-shadow: 7px 7px 15px rgba(0, 0, 0, 0.3)
}

.header_left-content_bottom {
    margin-top: 7rem;
    margin-bottom: 2rem;
}

.header_left-content_bottom {
    display: flex;
    gap: 2rem;
}

.header_left-content_bottom-content {
    display: flex;
    align-items: center;
}

.header_left-content_bottom-content-year {
    font-family: var(--font-family);
    color: var(--color-text-white);
    font-size: 2.7rem;
}

.header_left-content_bottom-content-text {
    font-family: var(--font-family);
    color: var(--color-text-grey);
    font-size: 1rem;
    margin-left: 1rem;
}

.header_left-content_bottom-content-right {
    margin-left: 2rem;
}

/* Header Right Content */
.header_right-content {
    position: relative;
    width: 50%;
}

.header_right-content-background {
    position: absolute;
    width: 40rem;
    height: 40rem;
    border-radius: 20rem;
    background: linear-gradient(135deg, var(--color-bg-dark) 5%, var(--color-bg-light) 100%);
    bottom: 0;
    margin-left: 3rem;
}

.header_right-content-profile {
    position: absolute;
    bottom: 0;
    margin-left: 3rem;
}

.header_right-content-spring_boot-icon {
    position: absolute;
    width: 9rem;
    height: 9rem;
    background-color: var(--color-bg-dark);
    align-items: center;
    padding: 2.5rem;
    border-radius: 4.5rem;
    box-shadow: -5px 5px 40px rgba(0, 0, 0, 0.6);
    bottom: 10rem;
}

.header_right-content-react-icon {
    position: absolute;
    font-size: 6rem;
    color: #5ED4F3;
    background-color: var(--color-bg-dark);
    padding: 2rem;
    border-radius: 3rem;
    box-shadow: -5px 5px 40px rgba(0, 0, 0, 0.6);
    right: 9rem;
    top: 12rem;
}

.header_right-content-android-icon {
    position: absolute;
    font-size: 11rem;
    color: #78C257;
    background-color: var(--color-bg-dark);
    padding: 3rem;
    border-radius: 5.5rem;
    box-shadow: -5px 5px 40px rgba(0, 0, 0, 0.6);
    right: 6rem;
    bottom: 5rem;
}

.header_right-content-dark_spot {
    position: absolute;
    width: 2.5rem;
    height: 2.5rem;
    background-color: var(--color-bg-dark);
    border-radius: 1.25rem;
    box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.1);
    right: 8rem;
    top: 5rem;
}

.header_right-content-green_spot {
    position: absolute;
    width: 0.8rem;
    height: 0.8rem;
    background-color: var(--color-primary);
    border-radius: 0.4rem;
    box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.1);
    right: 5rem;
    bottom: 18rem;
}

@media screen and (max-width: 1200px) {

    .header {
        padding: 8rem 0 0 5rem;
    }

    .header_left-content_middle {
        margin-left: 5rem;
    }

    .header_left-content_middle-designation {
        gap: 1rem;
    }

    .header_left-content_middle-designation p {
        font-size: 0.7rem;
    }

    .header_left-content_middle-heading {
        font-size: 1.5rem;
    }

    .header_left-content_middle-description {
        font-size: 1rem;
    }

    .header_left-content_bottom-content-year {
        font-size: 2rem;
    }

    .header_left-content_bottom-content-text {
        font-size: 0.8rem;
        margin-left: 0.7rem;
    }

    /* Header Right Content */

    .header_right-content-background {
        width: 30rem;
        height: 30rem;
        border-radius: 15rem;
        margin-left: 0;
    }

    .header_right-content-profile {
        height: 30rem;
        margin-left: 0;
    }

    .header_right-content-spring_boot-icon {
        position: absolute;
        width: 7rem;
        height: 7rem;
        padding: 2rem;
        border-radius: 3.5rem;
        bottom: 7rem;
        left: -3rem;
    }

    .header_right-content-react-icon {
        font-size: 4.5rem;
        padding: 1rem;
        border-radius: 2.25rem;
        left: 27rem;
        top: 27rem;
    }

    .header_right-content-android-icon {
        font-size: 8rem;
        padding: 2rem;
        border-radius: 4rem;
        left: 23rem;
        bottom: 3rem;
    }

    .header_right-content-dark_spot {
        position: absolute;
        width: 1.7rem;
        height: 1.7rem;
        border-radius: 0.85rem;
        left: 30rem;
        top: 23rem;
    }

    .header_right-content-green_spot {
        left: 32rem;
        bottom: 13rem;
    }
}

@media screen and (max-width: 1024px) {
    .header {
        flex-direction: column;
    }

    .header_left-content {
        margin-right: 0;
        width: 100%;
    }

    .header_right-content {
        margin-left: 0;
        width: 100%;
        margin-top: 35rem;
    }

    .header_right-content-background {
        width: 35rem;
        height: 35rem;
        border-radius: 17.5rem;
        margin-left: 10rem;
    }

    .header_right-content-profile {
        height: 35rem;
        margin-left: 10rem;
    }

    .header_right-content-spring_boot-icon {
        width: 9rem;
        height: 9rem;
        padding: 2.5rem;
        border-radius: 4.5rem;
        bottom: 7rem;
        left: 6rem;
    }

    .header_right-content-react-icon {
        position: absolute;
        font-size: 6rem;
        padding: 1.5rem;
        border-radius: 3rem;
        left: 40rem;
        top: -27rem;
    }

    .header_right-content-android-icon {
        font-size: 11rem;
        padding: 3rem;
        border-radius: 5.5rem;
        left: 38rem;
        bottom: 3rem;
    }

    .header_right-content-dark_spot {
        position: absolute;
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 1.25rem;
        left: 45rem;
        top: -35rem;
    }

    .header_right-content-green_spot {
        left: 52rem;
        bottom: 17rem;
    }
}

@media screen and (max-width: 768px) {
    .header_right-content-background {
        width: 30rem;
        height: 30rem;
        border-radius: 15rem;
        margin-left: 4rem;
    }

    .header_right-content-profile {
        height: 30rem;
        margin-left: 4rem;
    }

    .header_right-content-spring_boot-icon {
        width: 8rem;
        height: 8rem;
        padding: 2rem;
        border-radius: 4rem;
        bottom: 6rem;
        left: 1rem;
    }

    .header_right-content-react-icon {
        position: absolute;
        font-size: 5rem;
        padding: 1.2rem;
        border-radius: 2.5rem;
        left: 30rem;
        top: -24rem;
    }

    .header_right-content-android-icon {
        font-size: 9rem;
        padding: 2rem;
        border-radius: 4.5rem;
        left: 28rem;
        bottom: 2.5rem;
    }

    .header_right-content-dark_spot {
        position: absolute;
        width: 2rem;
        height: 2rem;
        border-radius: 1rem;
        left: 35rem;
        top: -30rem;
    }

    .header_right-content-green_spot {
        left: 40rem;
        bottom: 14rem;
    }
}

@media screen and (max-width: 480px) {

    .header {
        padding: 2rem 2rem 0 2rem;
    }

    .header_left-content_middle {
        margin-left: 0;
    }

    .header_left-content_middle button {
        font-size: 0.8rem;
    }

    .header_left-content_middle-designation p {
        font-size: 0.7rem;
    }

    .header_left-content_middle-heading {
        font-size: 1rem;
    }

    .header_left-content_middle-description {
        font-size: 0.8rem;
        margin-right: 0;
    }

    .header_left-content_bottom {
        margin-top: 5rem;
    }

    .header_left-content_bottom-content-year {
        font-size: 1.8rem;
    }

    .header_left-content_bottom-content-text {
        font-size: 0.7rem;
        margin-left: 0.4rem;
    }

    /* Header Right Content */

    .header_right-content {
        margin-top: 20rem;
    }

    .header_right-content-background {
        width: 19rem;
        height: 19rem;
        border-radius: 9.5rem;
        margin-left: 0;
    }

    .header_right-content-profile {
        height: 19rem;
        margin-left: 0;
    }

    .header_right-content-spring_boot-icon {
        width: 5rem;
        height: 5rem;
        padding: 1rem;
        border-radius: 2.5rem;
        bottom: 3rem;
        left: -2rem;
    }

    .header_right-content-react-icon {
        font-size: 3.5rem;
        padding: 0.8rem;
        border-radius: 1.75rem;
        left: 16rem;
        top: -15rem;
    }

    .header_right-content-android-icon {
        font-size: 6rem;
        padding: 1rem;
        border-radius: 3rem;
        left: 14.5rem;
        bottom: 1rem;
    }

    .header_right-content-dark_spot {
        position: absolute;
        width: 1.4rem;
        height: 1.4rem;
        border-radius: 0.7rem;
        left: 18rem;
        top: -20rem;
    }

    .header_right-content-green_spot {
        left: 19.5rem;
        bottom: 8.5rem;
    }
}